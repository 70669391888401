<template>
<div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
   
<div id="page-content" v-if="!viLoader">
  <!-- <div class="dispflex" >
    <h1 class="hdrtitle" style="margin-top: 0px;">School Hub</h1>
      <button type="button" class="backbtn" @click="previousRoute">
        <i class="fa fa-arrow-left"></i>
      </button>
  </div> -->
  <div class="webNew-dispflex"  >
       
       <div class="backbox">
         <div class="back-arrow" @click="previousRoute">
           <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
         </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        School Hub
       </h4>
      </div>

  <div class="row">
    <div class="col-lg-12">
      <div class="widjet">
        <div class="widjethdr dispflex" >
          <div style="width: auto" class="flexitm">
            <span class="iconsect blubg" style="padding-left: 11px"
              ><i class="fas fa-user-tie"></i
            ></span>
            <h1> {{ $route.path != '/enroll/schoolType' ? 'Manage School type' : 'Manage School type' }}</h1>
          </div>
          <!-- <div style="margin-top:10px" class="flexitm">
                <input v-if="currentTab == 0" type="text" v-model="searchTeacher" placeholder="Search Teachers">
                  <input v-if="currentTab == 1" v-model="searchUser" type="text" placeholder="Search User">
          </div> -->
          <div class="mt-1 flexitm">
            <!-- <button class="btn gry_bg">
              <i class="fas fa-search"></i>
            </button> -->
            
            <button v-if="currentTab == 0" style="margin-right: 20px;"
              class="btn gry_bg tooltipt mpopup twoicn"
                       @click.prevent="openModelTwo"

            >
            
              <i class="fas fa-user-tie user1"></i>
              <p class="user2"> <i class="fas fa-plus "></i></p>
              <span class="tooltiptext">Add School Type</span>
            </button>
            
              <!-- <button v-if="currentTab == 1 && $route.path != '/enroll/schoolType'"
              class="btn gry_bg tooltipt mpopup twoicn" style="margin-right: 20px;"
              v-b-modal.modalOne
            >
            
              <i class="fas fa-user-tie user1"></i>
              <p class="user2"> <i class="fas fa-plus "></i></p>
              <span class="tooltiptext">Add DepartMent</span>
            </button> -->
            
          </div>
        </div>
        <div class="p-3 widjetcontent">
          <div class="tbllist mt-1">
            <div class="restable">
        <div class="resrow resheader">
          <div class="cell">School Type Name</div>
          <div class="cell">Last Modified By</div>
          <div class="cell">Last Modified Date</div>
        </div>
        <div
          class="resrow"
          v-for="(schoolType, index) in allSchoolTypeList"
          :key="index"
        >
         
          <div class="cell" data-title="Department Id">
            {{ schoolType.name }}
          </div>
         
            <div class="cell" data-title="Department Name">
            {{ schoolType.updatedBy ? schoolType.updatedBy.firstName + " " +  schoolType.updatedBy.lastName : '' }}
          </div>
          <div  class="cell" data-title="Role">
                {{ schoolType.updatedDate | dateFormat }}
          </div>
          
        </div>
    </div>
     
        </div>

        <!-- <div class="tabs" style="height: 300%;background:none"> -->
          <!-- <input
          type="radio"
          name="tabs"
          @click="setActiveTab(0)"
          id="classestab"
          checked="checked"
        />
        <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" for="classestab">Manage School Type </label>
          <div class="tab">
        <div class="tbllist mt-1">
            <div class="restable">
        <div class="resrow resheader">
          <div class="cell">School Type Name</div>
          <div class="cell">Last Modified By</div>
          <div class="cell">Last Modified Date</div>
        </div>
        <div
          class="resrow"
          v-for="(schoolType, index) in allSchoolTypeList"
          :key="index"
        >
         
          <div class="cell" data-title="Department Id">
            {{ schoolType.name }}
          </div>
         
            <div class="cell" data-title="Department Name">
            {{ schoolType.updatedBy ? schoolType.updatedBy.firstName + " " +  schoolType.updatedBy.lastName : '' }}
          </div>
          <div  class="cell" data-title="Role">
                {{ schoolType.updatedDate | dateFormat }}
          </div>
          
        </div>
    </div>
     
        </div>
          </div> -->
        <!-- <input v-if="$route.path != '/enroll/schoolType'"
          type="radio"
          name="tabs"
          @click="setActiveTab(1)"
          id="stafftab"
        />
        <label style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;" v-if="$route.path != '/enroll/schoolType'" for="stafftab">Manage Departments</label> -->
          <!-- <div class="tab" v-if="$route.path != '/enroll/schoolType'" >
        <div >
            <div class="restable">
        <div class="resrow resheader">
          <div class="cell">Department Name</div>
          <div class="cell">Last Modified By</div>
          <div class="cell">Last Modified Date</div>
          <div class="cell">Action</div>
        </div>
        <div
          class="resrow"
          v-for="(department, index) in departmentList"
          :key="index"
        >
         
          <div class="cell" data-title="Department Name">
            {{ department.departmentName }}
          </div>
            <div class="cell" data-title="Department Name">
            {{ department.updatedBy ? department.updatedBy.firstName + " " +  department.updatedBy.lastName : '' }}
          </div>
          <div  class="cell" data-title="Role">
                {{ department.updatedDate | dateFormat }}
              </div>
          <div class="cell" data-title="Action">
            <button
              class="btn round orange tooltipt"
              @click="showUpdateModal(index)"
            >
              <i class="fas fa-edit"></i
              ><span class="tooltiptext">Edit</span>
            </button>
              <button class="btn round red tooltipt" @click="showDeleteModal(department._id)"  >
                    <i class="far fa-trash-alt"></i> <span class="tooltiptext">Delete</span>
                </button>
          </div>
        </div>
    </div>
      <div class="text-center mt-4" v-if="departmentList.length > 0">
        <div class="pagination" id="pagination">
          <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a>
          <a
            v-for="(item, index) in lastPageNumber"
            :key="index"
            v-on:click="selected = item"
            v-bind:class="{ active: selected == item }"
            @click="navToSelectedPage(item)"
            >{{ item }}</a
          >
          <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a>
        </div>
      </div>
        </div>
          </div> -->
          <!-- </div> -->
        </div>

      
      </div>
    </div>
  </div>
</div>
<div>
<b-modal id="modalOne" class="modal" no-close-on-backdrop no-close-on-esc>
  <button type="button" class="close" v-if="!isUpdate" @click.prevent="closePoup">×</button>
  <button type="button" class="close" v-if="isUpdate" @click.prevent="closeUpdateModal">×</button>

<!-- <div class="modal-content"> -->
<div class="contact-form">
<!-- <span class="close">&times;</span> -->
<h2 id="poptitle">Add Department</h2>
<br />
<div id="extFields">
  <form data-vv-scope="department"  accept-charset="utf-8">
    <div class="form-row">
      <div class="form-group input-group col-md-6 mt-3 pr-md-3" v-if="isUpdate">
        <span class="has-float-label">
          <input
            type="text"
            name="deptmentId"
            v-model="department.departmentId"
            class="form-control form-input"
            id="deptid"
            disabled
            placeholder="Department Id"
          />
          <label
            v-if="errors.first('department.deptmentId')"
            class="vi-error"
            for="emailid"
            style="top:-1em"
            >Department Id is required</label
          >
          <label for="deptid" v-else
            >Department Id <span class="required">*</span></label
          >
        </span>
      </div>
      <div class="form-group input-group col-md-6 mt-3">
        <span class="has-float-label">
          <input
              @input="checkDepartMent"
            v-validate="'required'"
            type="text"
            name="department name"
            v-model.trim="department.departmentName"
            class="form-control form-input"
            id="deptid"
            placeholder="Department Name"
          />
            
                
          <label
            v-if="errors.first('department.department name') || !isDeptNameAvailable "
            class="vi-error"
            for="emailid"
            style="top:-1em"
            >{{ errors.first('department.department name') ? 'Department name is required ' : 'Department name already exist. please use a different one.' }} </label
          >
          <label for="deptid" v-else
            >Department Name <span class="required">*</span></label
          >
        </span>
      </div>
    </div>
    
    <div
      class=" mt-3 pt-3"
      style="border-top: 1px solid #e5e5e5"
    >
      <!-- <div class="dk_icon"> -->
        <!-- <button
          type="button"
          class="btn btnsml mpopup"
          id="svbtn"
          @click.prevent="openModelTwo"
          title="Create Custom Field"
        >
          <i class="fas fa-plus"></i> <span>Standard Departments</span>
        </button> -->
      <!-- </div> -->
      <div class="text-center dk_iconsml">
        <button
          type="button"
          class="btn btnsml"
          id="svbtn"
          @click.prevent="createDepartment"
          v-if="!isUpdate"
        >
            <span>Save</span>
        </button>
        <button
          type="button"
          @click.prevent="closePoup"
          class="btn btncl clsmdl"
          id="clbtn"
          v-if="!isUpdate"
        >
             <span>Cancel</span>
        </button>
        <button
          type="button"
          class="btn btnsml"
          id="svbtn"
          v-if="isUpdate"
          @click.prevent="updateDepartment"
        >
            <span>Update</span>
        </button>
        <button
          type="button"
          @click.prevent="closeUpdateModal"
          class="btn btncl clsmdl"
          id="clbtn"
          v-if="isUpdate"
        >
             <span>Cancel</span>
        </button>
      </div>
    </div>
  </form>
</div>
<div id="createfield" style="display: none">
  <form
    action=""
    method="post"
    name="createNewField"
    accept-charset="utf-8"
  >
    <div class="form-row">
      <div class="form-group input-group col-md-6 mt-3 pr-md-3">
        <span class="has-float-label">
          <input
            type="text"
            name="lblname"
            value=""
            class="form-control form-input"
            id="lblid"
            placeholder="Label Name"
            required="required"
          />
          <label for="lblid"
            >Label Name <span class="required">*</span></label
          >
        </span>
      </div>
      <div class="form-group input-group col-md-6 mt-3">
        <span class="has-float-label">
          <input
            list="dtypelist"
            value=""
            class="form-control form-input"
            id="dtypeid"
            placeholder="Data Type"
            required="required"
          />
          <label for="dtypeid"
            >Data Type <span class="required">*</span></label
          >
          <datalist id="dtypelist">
            <option value="String">String</option>
            <option value="Number">Number</option>
            <option value="Boolean">Boolean</option>
          </datalist>
        </span>
      </div>
      <div
        class="form-group input-group col-md-12 mt-3 pr-md-3"
        style="border-bottom: 1px solid #e5e5e5"
      >
        <label style="color: #6c757d" class="mr-3"
          >Is Mandatory<span class="required">*</span></label
        >
        <label class="custradio"
          >Yes
          <input type="radio" name="radio" />
          <span class="checkmark"></span>
        </label>
        <label class="custradio"
          >No
          <input type="radio" name="radio" />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <div
      class="pt-3 text-right dk_iconsml mt-3"
      style="border-top: 1px solid #e5e5e5"
    >
      <button
        type="button"
        class="btn btnsml"
        id="svbtn"
        onclick="saveForm()"
      >
          <span>Save</span>
      </button>
      <button
        type="button"
        class="btn btncl clsmdl"
        id="clbtn"
        onclick="hidecustfield()"
      >
           <span>Cancel</span>
      </button>
    </div>
  </form>
</div>
</div>

</b-modal>
<b-modal id="modalTwo" class="modal" no-close-on-backdrop no-close-on-esc>
  <button type="button" class="close"  @click.prevent="closeModelTwoPoup">×</button>
  <div class="contact-form">
    <h2 id="poptitle">Add School Type</h2>
    <br />
     <div class="row">
       <div class="col-lg-6">Select a School Type
    <div id="extFields" v-if="standardDepartmentList.length > 0">
      <form data-vv-scope="department"  accept-charset="utf-8">
        <div  v-for="(value, index) in standardDepartmentList" :key="index">
          <div class="form-row" >
          
          <div class="form-group input-group col-md-2 mt-3" >
            <label class="custcheckbox">
                  <input
               @input="setIsSelect(index)"
                type="checkbox"
                v-model="value.isselected"
                class="form-control"
                id="deptid"
              />
                    <span class="checkmarkchk"></span>
                    
                  </label>
             
          </div>
          <div class="form-group input-group col-md-6 mt-3" >
              {{ value.name }}
          </div>
        </div>
        </div>
      
       
      </form>
    </div>
   <div v-else style="margin-top: 11%;">
    Created all School Types. Please check School Type list view...
    
   </div>
    </div>
      <div class="col-lg-6">School Type(s) Selected
    <div id="extFields" v-if="unavauiableTypeList.length > 0">
      <form data-vv-scope="department"  accept-charset="utf-8">
        <div  v-for="(value, index) in unavauiableTypeList" :key="index">
          <div class="form-row" >
          
          <div class="form-group input-group col-md-2 mt-3" >
            <label class="custcheckbox">
                  <input
                 disabled
                 checked="checked"
                type="checkbox"
                class="form-control"
                id="deptid"
              />
                    <span class="checkmarkchk"></span>
                    
                  </label>
            
          </div>
          <div class="form-group input-group col-md-6 mt-3" >
              {{ value }}
          </div>
        </div>
        </div>
        
       
      </form>
    </div>
   
    </div>
     </div>
     <div
          class=" mt-3 pt-3"
        >
          <div class="dk_icon">
            <span v-if="standardDepartmentList.length > 0" class="required">* Note: By default a single Term will be created for each class, Any additional Terms can created by editing each class.</span>

          </div>
          <div class="text-center dk_iconsml">
            <button v-if="standardDepartmentList.length > 0"
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="createMultipleDepartment"
            >
                <span>Create</span>
            </button>
            <button
              type="button"
              @click.prevent="closeModelTwoPoup"
              class="btn btncl clsmdl"
              id="clbtn"
            >
                 <span>Cancel</span>
            </button>
            
          </div>
        </div>

    
  </div>
  <!-- </div> -->
</b-modal>
<b-modal id="deleteDepartMentPopup" no-close-on-backdrop no-close-on-esc>
  <button type="button" class="close"  @click.prevent="hideDeletePopUp">×</button>
  <!-- <div class="modal-content"> -->
    <div class="contact-form">
      <!-- <span class="close">&times;</span> -->
      <h2>Delete Confirmation</h2>
      <br />
      Are you sure you want to delete this record?
        <div
            class="pt-3 text-center dk_iconsml"
            style="border-top: 1px solid #e5e5e5"
          >
            <button
              type="button"
              class="btn btnsml"
              id="svbtn"
              @click.prevent="confirmDelete"
            >
               <span>Confirm</span>
            </button>
            <button @click.prevent="hideDeletePopUp" type="button" class="btn btncl clsmdl" id="clbtn">
                 <span>Cancel</span>
            </button>
          </div>
    </div>
  <!-- </div> -->
</b-modal>
</div>

<vi-spinner
                v-if="viLoader"
                text="Loading..."
                textColor="vi-brand-color"
                class="flex-fill h-100 vi-fs12"
                style="width: 100%;z-index: 111;top:0;left:0;background-color: rgba(255, 255, 255, 0.5);border-radius: 1rem;"
              />

</div>
</template>
<script>
import ViSpinner from "../Common/ViSpinner";
import ViService from "@/services/ViService";
import secureUI from "../../utils/secureUI";

export default {
  name: "Class",
  components: {
    ViSpinner,
  },
  data() {
    return {
      department: {
        _id: null,
        departmentId: null,
        departmentName: "",
      },
      viLoader: false,
      currentTab: 0,
      isDeptNameAvailable: true,
      prevDepartmentName: "",
      departmentList: [],
      departmentListAll: [],
      deleteId: null,
      isUpdate: false,
      searchWords: '',
      skip: 0, // set the skip info
      limit: 6, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: 0,
      standardDepartmentList: [],
      allSchoolTypeList: [],
      unavauiableTypeList: []
    };
  },

  created() {
    this.getDepartMentList();
    this.getDepartMentAllList()
    this.getSchoolTypeList()
  },
  computed: {
    styles(){
       var brwHeight = window.innerHeight;
       return{
         height: (brwHeight - 90) + 'px'
       };
      },
      getUserName(){
        let userData = secureUI.sessionGet("user");
        return userData.userDetails.firstName + " " + userData.userDetails.lastName
      }
     
  },
  watch: {
          searchWords: function (currentVal, oldVal) {
               let hasCurrentParam = currentVal || '';
               let hasOldParam = oldVal || '';

              if (hasCurrentParam != hasOldParam) {
                this.skip = 0
                   this.currentPageNumber = 1,
                   this.lastPageNumber = 1,
                   this.selected = 1,
                   this.totalCount = 0,
				           this.countAll = true,
                  this.getDepartMentList();
              }
          }
  },
  methods: {
    previousRoute(){
      let userData = secureUI.sessionGet("user");

    //  let data = localStorage.getItem('previousTab');
    //  localStorage.setItem("activeTab",data);
    //  localStorage.setItem("previousTab",data);
    //  this.$router.go(-1)
      

        if(userData.userDetails.account.version == 'freeVersion'){
            localStorage.setItem("activeTab",'/enrollment/school');
            localStorage.setItem("previousTab", '/enrollment/school');
            this.$router.push('/enrollment/school');
        }else{
          localStorage.setItem("activeTab", '/admission/home');
        localStorage.setItem("previousTab", '/admission/home');
        this.$router.push({ path: '/admission/home' });
        }
    },
    async navToSelectedPage(selectedPageNo) {

      this.skip = (this.limit * (selectedPageNo - 1));

      this.countAll = false

      this.getDepartMentList();
    },

    async goToNextPage() {
      this.countAll = false
      this.currentPageNumber = this.currentPageNumber + 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));

      this.getDepartMentList();
    },

    async goToPreviousPage() {
       this.countAll = false
      this.currentPageNumber = this.currentPageNumber - 1;
      this.selected = this.currentPageNumber;
      this.skip = (this.limit * (this.currentPageNumber - 1));
     
      this.getDepartMentList();
    },
    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
     setActiveTab(index){
      this.currentTab = index
    },

    showdrop() {
      var showdp = document.getElementById("showdropmenu");
      if (showdp.style.display === "block") {
        showdp.style.display = "none";
      } else {
        showdp.style.display = "block";
      }
      setTimeout(function() {
        showdp.style.display = "none";
      }, 10000);
      return false;
    },
     showDeleteModal(id){
      this.deleteId = id
      this.$bvModal.show("deleteDepartMentPopup");
    },
    async createDepartment() {

       let isFormValid = false;

       await this.$validator.validateAll('department').then(result => {
          isFormValid = result;
       });

      if (isFormValid && this.isDeptNameAvailable) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
      //  this.viLoader = true;

        const response = await ViService.viXPost(
          "/department/createDepartment",
          this.department,
          userData.token
        );
       // this.viLoader = null;

        if (response.isSuccess) {

          this.department.departmentId = null;
          this.department.departmentName = '' ;

           if(this.$route.path != '/admission/department'){
             this.$emit("callDepartMentList");
           }

          this.closePoup();
          this.getDepartMentList();
          this.$toasted.success(response.message);
          
          let getCounts = this.$store.getters.getAdmissionObjCounts
          getCounts.departMent = getCounts.departMent + 1

          this.$store.dispatch('setAdmissionObjCOunt', getCounts);

        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
     }
    },
    setIsSelect(index){
         this.standardDepartmentList.forEach((a, i) => {
           if(index == i){
             a.isselected = true
           }else{
             a.isselected = false
           }
        });
    },
    async createMultipleDepartment() {

    const filterBySelected = this.standardDepartmentList.filter( x => x.isselected)
    
    if(filterBySelected.length > 0){
      
      const  newDepartMentList = filterBySelected.map( x => x.name)

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {

        const response = await ViService.viXPost(
          "/department/createMultipleSchoolType",
          newDepartMentList,
          userData.token
        );

        if (response.isSuccess) {

          this.closeModelTwoPoup()
          this.closePoup();
          this.getSchoolTypeList();
          this.getDepartMentList();

          let getCounts = this.$store.getters.getAdmissionObjCounts
          getCounts.schoolType = getCounts.schoolType + 1

          
          const classAndSectionCount = response.data
          getCounts.class = classAndSectionCount.class
          getCounts.section = classAndSectionCount.section
          
          this.$store.dispatch('setAdmissionObjCOunt', getCounts);
          this.$toasted.success(response.message);

        } else {

          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }

        }
      }
    }else{
      this.$toasted.error('Please Select Minimum One');
    }
   
    },

    closePoup() {
      this.isDeptNameAvailable = true;
      this.department.departmentId = null;
      this.department.departmentName = '';
      this.$bvModal.hide("modalOne");
    },
    closeModelTwoPoup() {
      this.closePoup()
      this.standardDepartmentList = [];
      this.$bvModal.hide("modalTwo");
    },
    async openModelTwo() {

      const stDepartmentList = [
        "Kindergarten [Pre-KG,LKG,UKG]",
        "Lower Primary [1st - 5th std]",
        "Upper Primary School [6th - 10th]",
        "Regular School [Nursery - 10th]",
        "K12 Schools [Nursery - 12th]"
      ]
       let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {

        const response = await ViService.viXPost(
          "/department/checkStandardDepartment",
          stDepartmentList,
          userData.token
        );

        if (response.isSuccess) {

          if(response.data.length == 0){
            for(let x of stDepartmentList){
              this.standardDepartmentList.push({
                name: x,
                isselected: false
              })
            }
          }else{
            let newDepartNameList = []

            const stDepartmentList = [
              "Kindergarten [Pre-KG,LKG,UKG]",
              "Lower Primary [1st - 5th std]",
              "Upper Primary School [6th - 10th]",
              "Regular School [Nursery - 10th]",
              "K12 Schools [Nursery - 12th]"
            ]

            const oldDepartMentNames = response.data.map( x => x.name)

            const checkRegularSchool = oldDepartMentNames.filter(x => x == 'Regular School [Nursery - 10th]')
            
            const checkK1School = oldDepartMentNames.filter(x => x == 'K12 Schools [Nursery - 12th]')

            if(checkRegularSchool.length > 0){

              this.standardDepartmentList = [{
                 name: 'K12 Schools [Nursery - 12th]',
                 isselected: false
               }]

                this.unavauiableTypeList = [
                  "Kindergarten [Pre-KG,LKG,UKG]",
                  "Lower Primary [1st - 5th std]",
                  "Upper Primary School [6th - 10th]",
                  "Regular School [Nursery - 10th]",
                  
                ]

            }else if(checkK1School.length > 0){
//this.unavauiableTypeList = []
              this.standardDepartmentList = []
              this.unavauiableTypeList = [
              "Kindergarten [Pre-KG,LKG,UKG]",
              "Lower Primary [1st - 5th std]",
              "Upper Primary School [6th - 10th]",
              "Regular School [Nursery - 10th]",
              "K12 Schools [Nursery - 12th]"
            ]

            }else{
             newDepartNameList = stDepartmentList.filter(val => !oldDepartMentNames.includes(val));
             this.unavauiableTypeList = stDepartmentList.filter(val => !newDepartNameList.includes(val));


            for(let x of newDepartNameList){
              this.standardDepartmentList.push({
                name: x,
                isselected: false
              })
            }
            }

           if(checkK1School.length > 0){
             this.unavauiableTypeList = [
              "Kindergarten [Pre-KG,LKG,UKG]",
              "Lower Primary [1st - 5th std]",
              "Upper Primary School [6th - 10th]",
              "Regular School [Nursery - 10th]",
              "K12 Schools [Nursery - 12th]"
            ]
              this.standardDepartmentList = []
           }

         
          }
          
          this.$bvModal.show("modalTwo");
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }

      
    },    

    async getDepartMentList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/department/getDepartmentsListView?skip=${this.skip}&limit=${this.limit}&search=${this.searchWords}&isCount=${this.countAll}`,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.departmentList = resultData.departmentList
          if(this.countAll){
            this.totalCount = resultData.totalCount;
            this.lastPageNumber = Math.ceil(this.totalCount/this.limit);
          }
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    async getSchoolTypeList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        const response = await ViService.viXGet(
          `/department/getSchoolTypeListView?viewType=list`,
          userData.token
        );
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);

          this.allSchoolTypeList = resultData
         
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
    
    async getDepartMentAllList() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/department/getDepartments?viewType=list",
          userData.token
        );

        if (response.isSuccess) {
          this.departmentListAll = secureUI.secureGet(response.data);
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
        this.viLoader = false;
      }
    },

    showUpdateModal(index) {
      let department = this.departmentList[index];
      
      this.department._id = department._id;
      this.department.departmentId = department.departmentId;
      this.department.departmentName = department.departmentName;
      this.prevDepartmentName = department.departmentName
      this.isUpdate = true;
      this.$bvModal.show("modalOne");
    },

    closeUpdateModal() {
      this.$bvModal.hide("modalOne");
      this.isUpdate = false;
      this.department.departmentId = null;
      this.department.departmentName = '' ;
      this.isDeptNameAvailable = true;
    },

    async updateDepartment() {

      let isFormValid = false;

       await this.$validator.validateAll('department').then(result => {
          isFormValid = result;
       });

      if (isFormValid && this.isDeptNameAvailable) {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
       this.$router.push('/login');
      } else {
        //this.viLoader = true;
        const response = await ViService.viXPut(
          `/department/updateDepartment/${this.department._id}`,
          this.department,
          userData.token
        );
       // this.viLoader = null;

        if (response.isSuccess) {
          this.closeUpdateModal();

           if(this.$route.path != '/admission/department'){
             this.$emit("callDepartMentList");
            }

          this.getDepartMentList();
          this.department.departmentId = null;
          this.department.departmentName = '' ;
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
           if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
      }
    },
     async checkDepartMent() {

        let allowDupUser = true;

        if (this.department._id) {
          // check the current key value is changes
          if ( this.department.departmentName == this.prevDepartmentName) {
            allowDupUser = false;
          } 
        }

        if (allowDupUser) {
          this.department.departmentName = this.department.departmentName.trim()
          
          const details = this.departmentListAll.find((x) => x.departmentName == this.department.departmentName);
           if(details){
              this.isDeptNameAvailable = false
           }else{
              this.isDeptNameAvailable = true
           }
        }
    },
    hideDeletePopUp(){
     this.deleteId = null
     this.$bvModal.hide("deleteDepartMentPopup");

    },
    async confirmDelete() {

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
this.$router.push('/login');
      } else {
       // this.viLoader = true;
        const response = await ViService.viDelete(`/department/deleteDepartment/${this.deleteId}`,userData.token);

		if (response.isSuccess) {
			this.$bvModal.hide("deleteDepartMentPopup");
			this.deleteId = null;
      this.getDepartMentList();
      this.$toasted.success(response.message);
        } else {
            this.$toasted.error(response.message);
             if(response.message == 'Your session has expired, please login'){
                localStorage.removeItem('user');
                this.$router.push('/login');
         }
        }
      }
    },
  },
};
</script>
